<template>
  <div class="forgetAboutMeWithToken flex justify-center">
    <TokenStatus
        operation="CONFIRM_FORGET_ABOUT_ME"
        :token="forgetAboutMeToken"
        token-name="forgetAboutMeToken"
        @token-is-valid="() => tokenIsValid=true"
        @token-is-invalid="() => tokenIsValid=false"
        @token-has-expired="() => tokenIsValid=false"
    ></TokenStatus>
    <Transition>
      <form
          v-if="tokenIsValid"
          class="form"
      >
        <h1 class="heading2">Forget about me
          <hr class="gradientLine">
        </h1>
        We are sad to see you go. We will delete your account and all data about your person.
        <div class="flex items-center justify-between">
          <button
              class="button primary"
              type="button">
            Forget about me
          </button>
        </div>
      </form>
    </Transition>
  </div>
</template>

<script>
import { ref, watch} from "vue";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {CONFIRM_FORGET_ABOUT_ME} from "@/store/operations";

export default {
  name: "ForgetAboutMeWithToken",
  components: {
    TokenStatus,
  },
  props: {
    forgetAboutMeToken: {
      type: String,
      required: true
    }
  },
  setup() {
    const tokenIsValid = ref(null)
    const {setMessage, deleteMessage} = useStatusMessage(CONFIRM_FORGET_ABOUT_ME)

    deleteMessage()

    watch(tokenIsValid, value => {
      if (value === true) setMessage({isSuccess: true, body: "Your token is valid."})
      else if (value === false) setMessage({isError: true, body: 'Your token is invalid. It may have been used before or it may have expired. Please try again.'})
    })
    return {
      tokenIsValid,
    }
  }
}
</script>

<style scoped>

</style>